












































import { Component, Prop, Vue } from "vue-property-decorator";
import { AuditSurvey as IAuditSurvey } from "@/models/entities/audit-survey.interface";
import { audit as auditService } from "@/services/api/audit.service";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import store from "@/store";
import { ExportReponse } from "@/models/entities/export-response.interface";

const overlayModule = getModule(OverlayModule, store);

const AuditSurveyGroup = () =>
  import(
    /* webpackChunkName: "AuditSurveyGroup" */ "./components/AuditSurveyGroup.vue"
  );

@Component({
  components: {
    "audit-survey-group": AuditSurveyGroup,
  },
})
export default class AuditSurvey extends Vue {
  @Prop({ required: false, default: () => [], type: Array })
  private data: IAuditSurvey[];
  @Prop({ required: false, default: true, type: Boolean })
  private isSuccess: boolean;
  @Prop({ required: false, default: "", type: String })
  private endOn: string;
  @Prop({ required: false, default: "", type: String })
  private customerAuditCode: string;
  @Prop({ required: false, default: 0, type: Number })
  private auditSurveyId: number;

  private showAnomalyClosedButton;

  private source: AbortController = null;

  private created(): void {
    this.showAnomalyClosedButton = !this.isSuccess;
  }

  private get questionGroups(): string[] {
    return [...new Set(this.data.map((el) => el.auditEquipmentGroup))];
  }

  private get dataByGroup(): {
    [id: string]: IAuditSurvey[];
  } {
    return this.data.reduce((res, value) => {
      (res[value.auditEquipmentGroup] =
        res[value.auditEquipmentGroup] || []).push(value);
      return res;
    }, {});
  }

  private closeDialog(ignoreReload = true): void {
    this.$emit("close", ignoreReload);
  }

  private async closeAnomalies() {
    overlayModule.showOverlay();
    await auditService.updateIgnoreAnomalies(this.auditSurveyId, 1);
    this.showAnomalyClosedButton = false;
    this.closeDialog(false);
  }

  private async downloadPDF() {
    overlayModule.showOverlay();
    this.source && this.source.abort();
    this.source = new AbortController();
    const r = await auditService.exportAuditPDF(
      this.customerAuditCode,
      this.auditSurveyId,
      this.source.signal
    );
    this.saveByteArray(r.data);
  }

  private saveByteArray(response: ExportReponse): void {
    const byteCharacters = atob(response.base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: response.contentType });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = response.fileName;
    link.click();
  }
}
